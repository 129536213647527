var items = document.querySelectorAll('.logo-slider--slider_image').length;

var logo_slider = tns({
    container: '.logo-slider--slider',
    items: 2,
    slideBy: 1,
    autoplay: false,
    nav: true,
    controlsText: [
        '<i class="customicon-arrow-left"></i>',
        '<i class="customicon-arrow-right"></i>',
    ],
    onInit: function() { // some accessibility improvements
        document.querySelector('.logo-slider .tns-controls').removeAttribute('aria-label');
        document.querySelector('.logo-slider .tns-controls button[data-controls="prev"]').setAttribute('aria-label', 'Previous');
        document.querySelector('.logo-slider .tns-controls button[data-controls="next"]').setAttribute('aria-label', 'Next');
    },
    responsive: {
      768: {
        items: items > 3 ? '3' : items,
      },
      991: {
        items: items > 5 ? '5' : items,
      }
    }
});